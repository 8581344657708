import { z as zod } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useEffect, useCallback, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import CryptoJS from "crypto-js";
import { useRouter } from '../../routes/hooks';

import { useBoolean } from '../../Common/hooks/use-boolean';

import { Form, Field, schemaHelper } from '../../mui-components/hook-form';

import { PostDetailsPreview } from './post-details-preview';
import JoditEditor from 'jodit-react';
import { useLocation, useParams } from 'react-router-dom';
import { commonService } from '../../CommonService';

// ----------------------------------------------------------------------

export const NewPostSchema = zod.object({
  title: zod.string().min(1, { message: 'Title is required!' }),
  permaLink: zod.string().min(1, { message: 'Permalink is required!' }),
  // content: schemaHelper.editor().min(100, { message: 'Content must be at least 100 characters' }),
  coverUrl: schemaHelper.file({ message: { required_error: 'Cover is required!' } }),
  // tags: zod.string().array().min(2, { message: 'Must have at least 2 items!' }),
  // metaKeywords: zod.string().array().nonempty({ message: 'Meta keywords is required!' }),
});

// ----------------------------------------------------------------------

export function PostNewEditForm({ currentPost, setEditorContent, editor, editorContent, handleEditorChange, onPublishClick }) {
  const router = useRouter();
  const location = useLocation();
  const [blogName, setBlogName] = useState('');
  const preview = useBoolean();
  const { blogId } = useParams();

  const editorRef = useRef(null);

  useEffect(() => {
    const handleSpace = (e) => {
      if (e.code === 'Space' && editorRef.current?.contains(e.target)) {
        e.preventDefault(); // Prevent default scrolling behavior
      }
    };

    document.addEventListener('keydown', handleSpace);
    return () => document.removeEventListener('keydown', handleSpace);
  }, []);


  useEffect(() => {
    const regex = /^\/([^\/]+)\/([^\/]+)/;
    const match = currentPost?.customLink?.match(regex);
    if(currentPost?.body) {
      setEditorContent(currentPost?.body);
    }
    if (match) {
      const firstPart = match[1];
      setBlogName(firstPart);
    } else {
      console.log('No match found.');
    }
  },[currentPost]);

  const defaultValues = useMemo(
    () => ({
      title: currentPost?.title || '',
      content: currentPost?.body || '',
      coverUrl: currentPost?.image || null,
      metaTitle: currentPost?.metaTitle || '',
      description: currentPost?.description || '',
      keywords: currentPost?.keywords || [],
      permaLink: currentPost?.customLink.replace(/^\//, "") || '',
      publish: currentPost?.status === 'PUBLISHED' ? 'true' : 'false'
    }),
    [currentPost, blogName]
  );

  const methods = useForm({
    mode: 'all',
    resolver: zodResolver(NewPostSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();
  
  useEffect(() => {
    if (currentPost) {
      reset(defaultValues);
    }
  }, [currentPost, defaultValues, reset]);

  const iv = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_IV}` || "0123456789abcdef"
  );
  const key = CryptoJS.enc.Latin1.parse(
    `${process.env.REACT_APP_KEY}` || "DU16HY06MP985458"
  );
  const getHeaderKey = () => {
    return CryptoJS.AES.encrypt("unicaps#9876~uniblog@123#", key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    });
  };

  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const insertMetaTags = (htmlContent, metaTitle, metaDescription, metaKeywords) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Create a new head element if it doesn't exist
    if (!doc.head) {
      const head = doc.createElement('head');
      doc.documentElement.insertBefore(head, doc.body);
    }

    // Create meta tags
    const titleTag = `<title>${metaTitle}</title>`;
    const descriptionTag = `<meta name="description" content="${metaDescription}">`;
    const keywordsTag = `<meta name="keywords" content="${metaKeywords}">`;

    // Insert meta tags at the beginning of the head
    doc.head.insertAdjacentHTML('afterbegin', keywordsTag);
    doc.head.insertAdjacentHTML('afterbegin', descriptionTag);
    doc.head.insertAdjacentHTML('afterbegin', titleTag);

    // Return the modified HTML content
    return doc.documentElement.outerHTML;
  };

  const onSubmit = handleSubmit(async (data) => {

    const contentWithMetaTags = insertMetaTags(editorContent);
    const postData = { ...data, body: contentWithMetaTags, customLink: `/${values?.permaLink}`, blogStatus: values?.publish === 'true' ? 'PUBLISHED' : 'NOT_PUBLISHED', image: values?.coverUrl, id: blogId, metaTitle: values?.metaTitle, description: values?.description, keywords: values?.keywords };
    delete postData.coverUrl;

    if (data.coverUrl instanceof File) {
      try {
        const base64Image = await toBase64(data.coverUrl);
        postData.image = base64Image;
      } catch (error) {
        console.error('Error converting image to base64:', error);
        return;
      }
    } else {
      postData.image = data.coverUrl; // If the image is already a Base64 string or URL (for editing existing posts)
    }
    
    const key = getHeaderKey();
  
    try {
      if (location?.pathname?.includes('edit')) {
        await commonService.EditBlog(postData, key);
      } else {
        await commonService.CreateBlog(postData, key);
      }
      reset();
      preview.onFalse();
      // toast.success(currentPost ? 'Update success!' : 'Create success!');
      router.push('/admin/blog');
    } catch (error) {
      console.error('Error during blog submission:', error);
    }
  });

  const handleImageUpload = async (files) => {
    const file = files[0];
  
    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  
    try {
      const base64Image = await toBase64(file);
  
      // Insert the base64 image directly into the editor's current selection
      const newContent = `${editor.current.value} <img src="${base64Image}" alt="Uploaded Image" />`;
      editor.current.selection.insertHTML(`<img src="${base64Image}" alt="Uploaded Image" />`);
  
      // Ensure the updated content is set in the state after inserting the image
      setEditorContent(newContent);
      console.log(newContent, 'Updated content with image');
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  };

  const newControl = {
    name: "Upload Image",
    exec: function (editor) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      
      input.onchange = async function () {
        if (input.files && input.files.length) {
          const imageFile = input.files[0];
          const reader = new FileReader();
          
          reader.readAsDataURL(imageFile);
          reader.onload = () => {
            const image = editor.selection.j.createInside.element("img");
            image.setAttribute("src", reader.result);
            image.setAttribute("alt", imageFile.name);
            editor.selection.insertNode(image);
          };
        }
      };
    }
  };

  const defaultConfig = {
    readonly: false, 
    toolbarAdaptive: false, 
    toolbarSticky: false,
    showCharsCounter: false,
    focus: true,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', '|',
      'cut', 'copy', 'paste', '|',
      'print', 'about',
    ],
  };

  const customConfig = {
    readonly: false, 
    toolbarAdaptive: false, 
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', '|',
      'cut', 'copy', 'paste', '|',
      'print', 'about', '|',
      
    ],
    uploader: {
      insertImageAsBase64URI: true,
    },
    extraButtons: [newControl],
    events: {
      beforeFileUpload: async (files) => {
        await handleImageUpload(files);
        return false;
      },
      focus: () => {
        console.log('Editor focused');
      },
      blur: () => {
        console.log('Editor lost focus');
      },
    }
  };

  const joditConfig = useMemo(() => ({ ...defaultConfig, ...customConfig }));
  
  const handleRemoveFile = useCallback(() => {
    setValue('coverUrl', null);
  }, [setValue]);

  const renderDetails = (
    <Card>
      <CardHeader title="Details" subheader="Title, Blog Content, image..." sx={{ mb: 3 }} />

      <Divider />

      <Stack spacing={3} sx={{ p: 3 }}>
        <Field.Text name="title" label="Post title" />

        <Stack spacing={1.5}>
          <Typography variant="subtitle2">Content</Typography>
          <div className="w-full">
          <JoditEditor
            config={joditConfig}
            ref={currentPost ? editorRef : editor}
            value={editorContent}
            onBlur={(newContent) => {
              // Update editorContent on blur instead of every keystroke
              setEditorContent(newContent);
            }}
          />
        </div>
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant="subtitle2">Cover</Typography>
          <Field.Upload name="coverUrl" maxSize={3145728} onDelete={handleRemoveFile} />
        </Stack>
      </Stack>
    </Card>
  );

  const JOB_SKILL_OPTIONS = [
    {
      key: 'GAMBLING',
      value: 'Gambling'
    },
    {
      key: 'FOREX',
      value: 'Forex'
    }
  ]

  const renderProperties = (
    <Card>
      <CardHeader
        title="Properties"
        subheader="Additional functions and attributes..."
        sx={{ mb: 3 }}
      />

      <Divider />

      <Stack spacing={3} sx={{ p: 3 }}>
       
        <Field.Text name="permaLink" label="Permalink" />
        <Field.Text name="metaTitle" label="Meta title" />
        <Stack spacing={1.5}>
          <Field.Autocomplete
            name="blogCategory"
            placeholder="Select blog category here"
            options={JOB_SKILL_OPTIONS.map((option) => option.value)}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  color="info"
                  variant="soft"
                />
              ))
            }
          />
        </Stack>

        <Field.Text name="description" label="Meta description" fullWidth multiline rows={3} />

        <Field.Autocomplete
          name="keywords"
          label="Meta keywords"
          placeholder="+ Keywords"
          multiple
          freeSolo
          disableCloseOnSelect
          options={[]}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={option}
                size="small"
                color="info"
                variant="soft"
              />
            ))
          }
        />

      </Stack>
    </Card>
  );

  const renderActions = (
    <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end">
      <div>
        <Button color="inherit" variant="outlined" size="large" onClick={preview.onTrue}>
          Preview
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!location?.pathname?.includes('edit') ? 'Create Blog' : 'Save changes'}
        </LoadingButton>
      </div>
    </Box>
  );
  console.log(values, 'value')

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Stack spacing={5} sx={{ mx: 'auto' }}>
        {renderDetails}

        {renderProperties}

        {renderActions}
      </Stack>

      <PostDetailsPreview
        isValid={isValid}
        onSubmit={onSubmit}
        title={values.title}
        open={preview.value}
        content={values.content}
        onClose={preview.onFalse}
        coverUrl={values.coverUrl}
        isSubmitting={isSubmitting}
        description={values.description}
      />
    </Form>
  );
}